// it is actually used in index html file
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function initVariables({ apiVersion, features, env, isUnsupportedBrowser }) {
  window.ENV = env;
  window.FEATURES = features;
  window.API_VERSION = apiVersion;
  window.isUnsupportedBrowser = isUnsupportedBrowser;

  var bypassUnsupported = document.cookie.indexOf("allowUnsupported=true") !== -1;

  if (!!isUnsupportedBrowser && !bypassUnsupported) {
    window.location = "/unsupported.html";
  }
}
